const amplifyTheme = {
    name: 'Sign in Theme',
    tokens: {
      colors: {
        // Colors generated with https://mdigi.tools/color-shades/#ff9903
        brand: {
          primary: {
            10: {
              value: '#ffd89f',
            },
            80: {
              value: '#ff9903', // Used for Primary Buttons
            },
            90: {
              value: '#df8500',
            },
            100: {
              value: '#9f5f00',
            },
          },
        },
      },
      components: {
        button: {
          _hover: {
            borderColor: '#9f5f00',
            backgroundColor: '#ffd89f',
            color: '#9f5f00',
          },
  
          primary: {
            backgroundColor: '#ff9903',
            _hover: {
              borderColor: '#9f5f00',
              backgroundColor: '#df8500',
            },
            _active: {
              borderColor: '#ff9903',
              backgroundColor: '#fff',
              color: '#ff9903',
            },
          },
  
          link: {
            color: '#ff9903',
            _hover: {
              borderColor: '#9f5f00',
              backgroundColor: '#ffd89f',
              color: '#9f5f00',
            },
          },
        },
        fieldcontrol: {
          _focus: {
              borderColor: '#ff9903',
              boxShadow: `0 0 0 1px #ff9903`,
          },
        },
        tabs: {
          item: {
            backgroundColor: '#ff9903',
            color: '#fff',
            _active: {
              borderColor: '#ff9903',
              color: '#ff9903',
            },
          },
        },
        authenticator: {
          modal: {
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
            padding: '24px',
          },
          socialButton: {
            backgroundColor: 'white',
            borderColor: '#e0e0e0',
            borderRadius: '4px',
            borderWidth: '1px',
            color: '#333333',
            fontSize: '14px',
            height: '40px',
            marginBottom: '12px',
            
            _hover: {
              backgroundColor: '#ffd89f',
              borderColor: '#ff9903',
              color: '#9f5f00',
            },
            
            _active: {
              backgroundColor: '#ff9903',
              borderColor: '#df8500',
              color: 'white',
            },
            
            imageWrapper: {
              backgroundColor: 'transparent',
              padding: '8px'
            }
          },
          container: {
            padding: '20px',
            backgroundColor: 'white',
          }
        }
      },
    },
  };

export default amplifyTheme
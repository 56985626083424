const getTimestamp = () => {
  const now = new Date();
  return `[${now.getHours().toString().padStart(2, '0')}:${now
    .getMinutes()
    .toString()
    .padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}]`;
};

const logger = {
  log: (...args) => {
    if (import.meta.env.VITE_APP_REGION === "local" || import.meta.env.VITE_APP_ENDPOINT?.toString().includes('uat')) {
      console.log(getTimestamp(), ...args);
    }
  },
  error: (...args) => {
    if (import.meta.env.VITE_APP_REGION === "local" || import.meta.env.VITE_APP_ENDPOINT?.toString().includes('uat')) {
      console.error(getTimestamp(), ...args);
    }
  },
  warn: (...args) => {
    if (import.meta.env.VITE_APP_REGION === "local" || import.meta.env.VITE_APP_ENDPOINT?.toString().includes('uat')) {
      console.warn(getTimestamp(), ...args);
    }
  },
  info: (...args) => {
    if (import.meta.env.VITE_APP_REGION === "local" || import.meta.env.VITE_APP_ENDPOINT?.toString().includes('uat')) {
      console.info(getTimestamp(), ...args);
    }
  }
};

export default logger; 
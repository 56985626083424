import React from 'react';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';

import awsExports from './aws-exports';
// LIBRARIES
import { BrowserRouter as Router } from 'react-router-dom';
import { Hub } from '@aws-amplify/core';
import { SnackbarProvider } from 'notistack';


// COMPONENTS
import MyRoutes from './Routes';
// FUNCTIONS
import { listener } from './functions/listner';

import '@aws-amplify/ui-react/styles.css';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/materialTheme';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import p from '../package.json';
import logger from './utils/logger';

import { ConsoleLogger } from 'aws-amplify/utils';
// import { ConsoleLogger, LOG_LEVEL } from '@aws-amplify/core/dist/esm/Logger';



// Create a query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // ✅ globally default to Infinity, you have to invalidate the query to refetch
      staleTime: process.env.NODE_ENV === 'development' ? Infinity : 0,
      refetchOnWindowFocus: process.env.NODE_ENV === 'development' ? false : true,
    },
  },
});

// Listen for AWS Amplify Auth Events
Hub.listen('auth', listener);


ConsoleLogger.LOG_LEVEL = 'DEBUG';
const AmplifyLogger = new ConsoleLogger('MyApp'); //, LOG_LEVEL.DEBUG


Amplify.configure(awsExports, {
  API: {
    REST: {
      headers: async () => {
        try {
          AmplifyLogger.debug('Fetching auth session for API headers');
          const session = await fetchAuthSession();
          AmplifyLogger.debug('Auth session retrieved', { hasToken: !!session.tokens?.idToken });
          if (session.tokens?.idToken) {
              return {
                IdToken: session.tokens.idToken.toString(),
              };
          }
          return {};
        } catch (error) {
          AmplifyLogger.debug('No auth session available', { error });
          return {};
        }
      },
    },
  },
  logging: {
    logger: AmplifyLogger,
    level: "DEBUG"
  }
});

// Amplify.Logger.LOG_LEVEL = "DEBUG";

function App() {
  console.log(`
  ░██████╗░█████╗░░█████╗░███╗░░██╗░██████╗███████╗░██████╗░██████╗
  ██╔════╝██╔══██╗██╔══██╗████╗░██║██╔════╝██╔════╝██╔═══██╗██╔══██╗
  ╚█████╗░██║░░╚═╝███████║██╔██╗██║╚█████╗░█████╗░░██║██╗██║██████╔╝
  ░╚═══██╗██║░░██╗██╔══██║██║╚████║░╚═══██╗██╔══╝░░╚██████╔╝██╔══██╗
  ██████╔╝╚█████╔╝██║░░██║██║░╚███║██████╔╝███████╗░╚═██╔═╝░██║░░██║
  ╚═════╝░░╚════╝░╚═╝░░╚═╝╚═╝░░╚══╝╚═════╝░╚══════╝░░░╚═╝░░░╚═╝░░╚═╝
  
  Welcome to ScanseQR - Smart Access Control
  Version: ${p.version}
  `);// Environment: ${process.env.NODE_ENV}

  logger.log('APP STARTED');

  
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <SnackbarProvider maxSnack={3} preventDuplicate>
            <MyRoutes />
          </SnackbarProvider>
        </Router>
        <ReactQueryDevtools initialIsOpen={true} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;

// import { fetchAuthSession } from '@aws-amplify/auth';

const awsExports = {

  Auth: {
    Cognito: {
      region: import.meta.env.VITE_APP_REGION,
      userPoolEndpoint: import.meta.env.VITE_APP_REGION === "local" ? `http://${import.meta.env.VITE_APP_HOST_IP !== undefined ? import.meta.env.VITE_APP_HOST_IP : 'localhost'}:9229` : null,
      //  Amazon Cognito User Pool ID
      userPoolId: import.meta.env.VITE_APP_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: import.meta.env.VITE_APP_USER_POOL_CLIENT_ID,
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: import.meta.env.VITE_APP_IDENTITY_POOL_ID,
      identityPoolRegion: import.meta.env.VITE_APP_REGION,
      //authenticationFlowType: import.meta.env.VITE_APP_REGION === "local" ? "USER_PASSWORD_AUTH" : null,
      // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
      allowGuestAccess: true,
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: 'code', // 'code' | 'link'
      aws_cognito_social_providers: import.meta.env.VITE_APP_SOCIAL_PROVIDERS !== undefined
        ? import.meta.env.VITE_APP_SOCIAL_PROVIDERS.split(',').filter((v) => v !== '')
            .map(provider => provider) //.map(provider => provider.toUpperCase())
        : ['google', 'facebook'],
      // aws_cognito_social_providers: ['google'],
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        // email: true,
        oauth: {
          domain: import.meta.env.VITE_APP_COGNITO_DOMAIN,
          scopes: [
            'phone',
            'email',
            'profile',
            'openid',
          ],
          redirectSignIn: [import.meta.env.VITE_APP_REDIRECT_URL],
          redirectSignOut: [import.meta.env.VITE_APP_REDIRECT_URL],
          responseType: 'code',
        },
      },
    },
  },



    // authenticationFlowType: process.env.REACT_APP_REGION === "local" ? "USER_PASSWORD_AUTH" : null,
    // endpoint: process.env.REACT_APP_REGION === "local" ? "http://localhost:9229" : null,
    // identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    // region: process.env.REACT_APP_REGION,
    // identityPoolRegion: process.env.REACT_APP_REGION,
    // userPoolId: process.env.REACT_APP_USER_POOL_ID,
    // userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    // aws_cognito_social_providers: process.env.REACT_APP_SOCIAL_PROVIDERS !== undefined
    //   ? process.env.REACT_APP_SOCIAL_PROVIDERS.split(",").filter(v => v !== "")
    //   : [
    //     "GOOGLE",
    //     "FACEBOOK"
    //   ],
    // oauth: {
    //   domain: process.env.REACT_APP_COGNITO_DOMAIN,
    //   scope: [
    //     "phone",
    //     "email",
    //     "openid",
    //     "profile",
    //   ],
    //   redirectSignIn: process.env.REACT_APP_REDIRECT_URL,
    //   redirectSignOut: process.env.REACT_APP_REDIRECT_URL,
    //   responseType: "code"
    //  },
    //  federationTarget: "COGNITO_USER_POOLS"
  // },
  // API: {
  //   endpoints: [
  //     {
  //       name: 'Scanseqr',
  //       endpoint: process.env.REACT_APP_ENDPOINT,
  //       region: process.env.REACT_APP_REGION,
  //       custom_header: async () => {
  //         return { IdToken: `${(await Auth.currentSession().catch(() => 'null'))?.idToken?.jwtToken}`};
  //       }
  //     }
  //   ]
  // }
  API: {
    REST: {
      Scanseqr: {
        endpoint: import.meta.env.VITE_APP_ENDPOINT,
        region: import.meta.env.VITE_APP_REGION,
        // custom_header: async () => {
        //   const session = await fetchAuthSession().catch(() => null);
        //   return { IdToken: session?.tokens?.idToken?.toString() ?? 'null' };
        // }
      },
    },
  },
};

export default awsExports;



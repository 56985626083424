import React, { useEffect, lazy, Suspense } from 'react';
import { Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom';
import { Authenticator, useAuthenticator, ThemeProvider } from '@aws-amplify/ui-react';
import { signIn } from 'aws-amplify/auth';

// COMPONENTS
import AppToolbar from './components/AppToolbar';
import BottomNavigator from './components/BottomNavigator';

import amplifyExports from './aws-exports';
import { AuthComponents, LoginFormFields } from './components/AuthenticatorComponents';

import { UserContextProvider } from './contexts/UserContext.js';

// Dynamically import pages
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Account = lazy(() => import('./pages/Account'));
const Scan = lazy(() => import('./pages/Scan'));
const AccessToken = lazy(() => import('./pages/AccessToken'));
const Trigger = lazy(() => import('./pages/Trigger'));
const Invite = lazy(() => import('./pages/Invite'));

// FUNCTIONS
import { padding } from './functions/styleHelpers';
import qrCodeBackground from './assets/qrCode.png';

// THEME
import amplifyTheme from './styles/amplifyTheme';

const deviceActionUrls = [
  { code: 'ta', url: '/ta/:id', action: 'AuthToggle' },
  { code: 'th', url: '/th/:id', action: 'Check in' },
  { code: 'to', url: '/to/:id', action: 'On' },
  { code: 'tb', url: '/tb/:id', action: 'Check out' },
  { code: 'tt', url: '/tt/:id', action: 'Toggle' },
  { code: 'tp', url: '/tp/:id', action: 'Pulse' },
  { code: 'tm', url: '/tm/:id', action: 'Multi Channel' },
  { code: 'ks', url: '/ks/:id', action: 'Key Store' },
];

const RequireAuth = ({ children }) => {
  const location = useLocation();
  // console.log('checking access keys', location);

  if (deviceActionUrls.find((el) => location.pathname.split('/')[1] === el.code)) {
    // This only fires if a user enters a device action url or scans a QR code
    console.log('checking access keys');
    const deviceId = location.pathname.split('/')[2];

    // Keys are stored in the follow format:
    // [{ name: Key.name, endTime: Key.endTime, key: postBody.key, devices: [{name: device.name, uuid: device.uuid}...] }]
    const AccessKeys = JSON.parse(localStorage.getItem('AccessKeys') || null) || [];
    const validAccessKeys = AccessKeys.filter((k) => new Date(k.endTime) > new Date());
    // Update the local storage, removing any expired keys
    console.log('AccessKeys: ', AccessKeys);
    if (AccessKeys.length !== validAccessKeys.length) {
      localStorage.setItem('AccessKeys', JSON.stringify(validAccessKeys));
    }

    if (validAccessKeys && validAccessKeys.length > 0) {
      // Get All the valid keys for this device
      const keys = validAccessKeys.filter((k) => k.devices.find((d) => d.uuid === deviceId));
      console.log(keys);
      if (keys.length > 0) {
        // We always want to return the first key that is valid
        return <Navigate to={`/key/${encodeURIComponent(keys[0].key)}`} state={{ ...keys[0], uuid: deviceId, local: true }} />;
      }
    }

    console.log('no access keys found -> LOGIN if unAuthenticated');
  }

  const services = import.meta.env.VITE_APP_REGION === "local" ? {
    async handleSignIn(input) {
      // custom username and email
      const { username, password } = input;
      return signIn({
        username,
        password,
        options: {
          authFlowType: 'USER_PASSWORD_AUTH',
        },
      });
    },
  } : {
    async handleSignIn(input) {
      // Handle both password and social sign-in
      if (input.provider) {
        return signIn({ 
          provider: input.provider 
        });
      }
      
      const { username, password } = input;
      return signIn({
        username,
        password,
      });
    },
  };

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,0.1)), url(${qrCodeBackground})`,
        backgroundRepeat: 'repeat',
        backgroundColor: 'transparent',
        height: '100vh',
      }}
    >
      <ThemeProvider theme={amplifyTheme}>
        <Authenticator
          services={services}
          components={AuthComponents()}
          formFields={LoginFormFields}
          loginMechanisms={['email']}
          signUpAttributes={['phone_number', 'email']}
          socialProviders={amplifyExports.Auth.Cognito.aws_cognito_social_providers} //['google', 'facebook']
        >
          {({ signOut, user }) => (
            <UserContextProvider user={user}>
              <AppToolbar />
              <div style={padding(7, 7, 70, 7)}>
                <Suspense fallback={<div>Loading...</div>}>
                  <Outlet />
                </Suspense>
              </div>
              <BottomNavigator />
            </UserContextProvider>
          )}
        </Authenticator>
      </ThemeProvider>
    </div>
  );
}



const MyRoutes = () => {
  // const location = useLocation();
  // const { route } = useAuthenticator((context) => [context.route]);
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route index element={<Dashboard />} />
        <Route path='/dashboard' element={<Navigate to='/' />} />
        <Route path='/scan' element={<Scan initialScan={true} />} />
        <Route path='/account' element={<Account />} />
        <Route path='/trigger/:id' element={<Trigger method={'Dashboard Button'} />} />
        <Route path='/im/:id' element={<Invite />} />
        <Route path='/is/:id' element={<Invite />} />
        <Route path='/invite' element={<Invite />} />
        {deviceActionUrls.map((el) => (
          <Route key={el.url} path={el.url} element={<Trigger method={'Scanned QR'} />} />
        ))}

      </Route>
      <Route
        path='/key/:encodedData'
        element={
          <AccessToken />
        }
      />
      {/* <Route path='/login' element={<Login initialState={'signIn'} />} /> */}
      {/* {<Route path="/invite/:encodedData" element={route === 'authenticated' ? <Dashboard invite={location.pathname.split('/')[2]}/> : <Login initialState={"signUp"} invite={location.pathname.split('/')[2]}/>} />} */}
      {/* <Route path="/invite/:encodedData" element={<Login initialState={"signUp"} invite={location.pathname.split('/')[2]} />} /> */}


      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default MyRoutes;


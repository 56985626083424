import { useState, useEffect } from 'react';
import logger from '../utils/logger';

const defaultSettings = {
  enableHighAccuracy: true, // Enable high accuracy for better initial position
  timeout: 5000, // Set reasonable timeout of 5 seconds
  maximumAge: 60000, // Allow cached positions up to 30 seconds old
};

export const usePosition = (watch = false, lazy = false, userSettings = {}) => {
  const settings = {
    ...defaultSettings,
    ...userSettings,
  };

    // iOS doesn't have navigator.permissions.query, so we skip it if we failed to get the user's location
  // const requestLocation = async () => {
  //   if (!/(safari)/i.test(navigator.userAgent)) {
  //     const permission = await navigator.permissions.query({ name: 'geolocation' });
  //   }
  // }

  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);
  const [hasPermission, setHasPermission] = useState(null);
  const [isInitialized, setIsInitialized] = useState(!lazy);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = ({ coords, timestamp }) => {
    setError(null);
    setHasPermission(true);
    setIsLoading(false);
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
      accuracy: coords.accuracy,
      speed: coords.speed,
      heading: coords.heading,
      timestamp,
    });
  };

  const onError = (error) => {
    logger.log('LOCATION ERROR', error);
    setError(error);
    setPosition({});
    setHasPermission(false);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isInitialized) return;

    if (!navigator || !navigator.geolocation) {
      setError('Geolocation is not supported');
      setHasPermission(false);
      return;
    }

    setIsLoading(true);

    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' })
        .then(result => {
          setHasPermission(result.state === 'granted');
          
          // Listen for permission changes
          result.addEventListener('change', () => {
            setHasPermission(result.state === 'granted');
            if (result.state === 'granted') {
              navigator.geolocation.getCurrentPosition(onChange, onError, settings);
            }
          });

          // If permission is granted, get position immediately
          if (result.state === 'granted') {
            navigator.geolocation.getCurrentPosition(onChange, onError, settings);
          }
        });
    } else {
      // If permissions API is not available, try getting position directly
      navigator.geolocation.getCurrentPosition(onChange, onError, settings);
    }

    // Then set up watching if requested
    if (watch) {
      const watcher = navigator.geolocation.watchPosition(onChange, onError, settings);
      return () => navigator.geolocation.clearWatch(watcher);
    }
  }, [settings.enableHighAccuracy, settings.timeout, settings.maximumAge, watch, isInitialized]);

  const initializePosition = () => {
    setIsInitialized(true);
  };

  return { 
    ...position, 
    positionError: error,
    hasPermission,
    isLoading,
    initializePosition 
  };
};

import { useState, useEffect } from "react";
import { Flex, Image, Text, useTheme, Heading } from "@aws-amplify/ui-react";


import logo192 from "../assets/logo192.png";

export const LoginFormFields = {
	signIn: {
	  username: {
		labelHidden: true,
	  },
	  password: {
		labelHidden: true,
	  },
	},
	signUp: {
	  email: {
		labelHidden: true,
	  },
	  password: {
		labelHidden: true,
	  },
	  confirm_password: {
		labelHidden: true,
	  },
	  phone_number: {
		dialCode: '+61',
		labelHidden: true,
	  },
	},
  };

function Header() {
	const headerText = 'Welcome to ScanseQR'
	const [charIndex, setCharIndex] = useState(1)

	useEffect(() => {
		if (charIndex < headerText.length) {
			setTimeout(() => setCharIndex(charIndex + 1), 100)
		}
	}, [charIndex])

	return (
		<Flex justifyContent="center" alignItems={'center'} direction={'column'}>
			<Image
				alt="logo"
				src={logo192}
			/>
			<a href="https://www.scanseqr.com" target="_blank" rel="noreferrer"><Text>What is this?</Text></a>
			<Flex justifyContent="center" alignItems={'center'} direction={'column'} width={'100%'} backgroundColor={'#fff'} style={{border: '1px solid rgb(155, 152, 148)'}}>
				<Heading level={3} style={{
					marginTop: 5,
					fontFamily: 'Courier New',
					color: '#ff9903',
				}} >
					{headerText.slice(0, charIndex)}
				</Heading>
			</Flex>
		</Flex>
	);
}

function Footer() {
	const { tokens } = useTheme();

	return (
		<Flex justifyContent="center" padding={tokens.space.small}>
			<Text>&copy; ScanseQR All Rights Reserved</Text>
		</Flex>
	);
}

export const AuthComponents = () => ({
	Header,
	Footer,
});